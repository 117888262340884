import * as demoProductComponentsThumbnailReactRatiosTsx from '@content/product/components/thumbnail/react/ratios.tsx';
import * as demoProductComponentsThumbnailReactSizesTsx from '@content/product/components/thumbnail/react/sizes.tsx';
import * as demoProductComponentsThumbnailReactCombinedTsx from '@content/product/components/thumbnail/react/combined.tsx';
import * as demoProductComponentsThumbnailReactVariantsTsx from '@content/product/components/thumbnail/react/variants.tsx';
import * as demoProductComponentsThumbnailReactLoadingTsx from '@content/product/components/thumbnail/react/loading.tsx';
import * as demoProductComponentsThumbnailReactErrorTsx from '@content/product/components/thumbnail/react/error.tsx';
import * as demoProductComponentsThumbnailReactErrorFallbackTsx from '@content/product/components/thumbnail/react/error-fallback.tsx';
import { Thumbnail } from '@lumx/react';
import * as React from 'react'

export default { demoProductComponentsThumbnailReactRatiosTsx, demoProductComponentsThumbnailReactSizesTsx, demoProductComponentsThumbnailReactCombinedTsx, demoProductComponentsThumbnailReactVariantsTsx, demoProductComponentsThumbnailReactLoadingTsx, demoProductComponentsThumbnailReactErrorTsx, demoProductComponentsThumbnailReactErrorFallbackTsx, Thumbnail, React }